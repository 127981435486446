<template>
  <div class="index">
    <!-- 组件 -->
    <!-- 侧边栏组件 -->
    <slider-bar ref="sliderBar" />
    <!-- 路由组件 -->
    <router-view></router-view>
  </div>
</template>

<script>
import sliderBar from "../../components/slider-bar/slider-bar.vue";
export default {
  components: { sliderBar },
  mounted() {
    this.$socket.onmessage = this.getMessage;
  },
  beforeDestroy() {
    this.$socket.onmessage = null;
  },
  methods: {
    //获取监听的信息
    getMessage(msg) {
      const msgs = JSON.parse(msg.data);
      console.log(msgs);
      if (msgs.cmd == "20000") {
        this.$refs.sliderBar.logout();
      }
    },
  }
};
</script>

<style scoped>
.index {
  background: rgb(255, 255, 255);
  display: flex;
  height: 100%;
  width: 100%;
  /* width: 1000px; */
}
</style>