<template>
	<div class="slider">
		<header class="header">
			<div class="nickName-box">
				{{ loginMsg.nickName }}
			</div>
			<el-dropdown trigger="click" placement="top-start">
				<div class="avatar">
					<img v-if="avatar" :src="avatar" />
				</div>
				<el-dropdown-menu class="user-dropdown" slot="dropdown">
					<el-dropdown-item>
						<span @click="logout" style="display: block">退出</span>
					</el-dropdown-item>
				</el-dropdown-menu>
			</el-dropdown>
		</header>
		<ul class="slider-nav">
			<li v-for="(item, index) in navSrc" :key="index" @click="goMenu(index, item.path)">
				<img :src="current == index ? item.img : noActivePic[index].img" alt="" :title="item.name" />
				<span>{{ item.name }}</span>
				<span :class="uploadMemeNum < 10 ? 'badge' : 'badge2'"
					v-if="item.name == '私信' && upDateMessageNum > 0">{{ upDateMessageNum }}</span>
				<span :class="uploadMemeNum < 10 ? 'badge' : 'badge2'"
					v-if="item.name == '上传表情' && uploadMemeNum > 0">{{ uploadMemeNum }}</span>
				<span :class="uploadMusicNum < 10 ? 'badge' : 'badge2'"
					v-if="item.name == '上传音乐' && uploadMusicNum > 0">{{ uploadMusicNum }}</span>
				<span :class="uploadZhiKNum < 10 ? 'badge' : 'badge2'" v-if="item.name == '发蜘客' && uploadZhiKNum > 0">{{
					uploadZhiKNum }}</span>
				<span :class="upDateGroupNum < 10 ? 'badge' : 'badge2'"
					v-if="item.name == '秘书台' && upDateGroupNum > 0">{{ upDateGroupNum }}</span>
			</li>
		</ul>
	</div>
</template>

<script>
import api from "@/api/api";
import {
	mapState
} from "vuex";
import init from "@/assets/js/rongyun.js";

export default {
	data() {
		return {
			RongIMClient: null, //融云实例
			userInfo: {}, //用户信息
			loginMsg: {}, //登录信息
			current: 0,
			badge: 0, // 标记
			artistStatus: 0, //艺术家状态
			musicStatus: 0, //音乐家状态
			avatar: null, // 头像
			noActivePic: [{
				img: require("@/assets/images/ic_sixin1@3x.png"),
				name: "私信",
			},
			{
				img: require("@/assets/images/ic_ic_tongxunlu1@3x.png"),
				name: "通讯录",
			},
			{
				img: require("@/assets/images/ic_mishutai1@3x.png"),
				name: "秘书台",
			},
			{
				img: require("@/assets/images/ic_youjian1@3x.png"),
				name: "邮箱",
			},
			{
				img: require("@/assets/images/ic_xiezhike1@3x.png"),
				name: "发蜘客",
			},
			// {
			//   img: require("@/assets/images/ic_wenjian1@3x.png"),
			//   name: "文件",
			// },
			{
				img: require("@/assets/images/ic_yinyue1@3x.png"),
				name: "上传音乐",
			},
			{
				img: require("@/assets/images/ic_biaoqing1@3x.png"),
				name: "上传表情",
				num: "",
			},
			{
				img: require("@/assets/images/ic_biaoqing1@3x.png"),
				name: "上传视频",
				num: "",
			},
			],
			navSrc: [{
				img: require("@/assets/images/ic_sixin2@2x.png"),
				name: "私信",
				path: "/index/privateLetter",
			},
			{
				img: require("@/assets/images/ic_ic_tongxunlu2@3x.png"),
				name: "通讯录",
				path: "/index/addressbook",
			},
			{
				img: require("@/assets/images/ic_mishutai2@3x.png"),
				name: "秘书台",
				path: "/index/secretaryStation",
			},
			{
				img: require("@/assets/images/ic_youjian2@3x.png"),
				name: "邮箱",
				path: "/index/mailbox",
			},
			{
				img: require("@/assets/images/ic_xiezhike2@3x.png"),
				name: "发蜘客",
				path: "/index/sendSpider",
			},
			// {
			//   img: require("@/assets/images/ic_wenjian2@3x.png"),
			//   name: "文件",
			//   path: "/index/file",
			// },
			{
				img: require("@/assets/images/ic_yinyue2@3x.png"),
				name: "上传音乐",
				path: "/index/uploadMusic",
			},
			{
				img: require("@/assets/images/ic_biaoqing2@3x.png"),
				name: "上传表情",
				path: "/index/uploadExpression",
			},
			{
				img: require("@/assets/images/ic_shipin1@2x.png"),
				name: "上传视频",
				path: "/index/uploadVideo",
			},
			],
		};
	},
	created() {
		this.initRongyun(); //初始化融云实例
		if (window.localStorage.getItem("index")) {
			let indexs = window.localStorage.getItem("index");
			this.current = indexs;
			this.goMenu(this.current, this.navSrc[this.current].path)
		}
		this.getArtistStatus(); //获取艺术家空间开通状态 0未开通  1开通
		this.getNum();
		this.isOpenZone() //获取是否开通蜘信空间
	},
	computed: {
		...mapState({
			uploadMemeNum: (state) => state.updateNum.uploadMemeNum, //上传表情包数量
			uploadMusicNum: (state) => state.updateNum.uploadMusicNum, //上传音乐数量
			uploadZhiKNum: (state) => state.updateNum.uploadZhiKNum, //发蜘客的数量
			upDateMessageNum: (state) => state.updateNum.upDateMessageNum, //私信的数量
			upDateGroupNum: (state) => state.updateNum.upDateGroupNum, //秘书台的数量
			targetId: (state) => state.rongyun.targetId, //当前聊天的目标id
		}),
	},
	watch: {
		//监听表情包的数量变化
		uploadMemeNum(newVal, oldVal) {
			oldVal = newVal;
		},
		//监听上传音乐的数量变化
		uploadMusicNum(newVal, oldVal) {
			oldVal = newVal;
		},
		//监听发蜘客的数量变化
		uploadZhiKNum(newVal, oldVal) {
			oldVal = newVal;
		},
		//监听私信的数量变化
		upDateMessageNum(newVal, oldVal) {
			oldVal = newVal;
		},
		//监听秘书台数量变化
		upDateGroupNum(newVal, oldVal) {
			oldVal = newVal;
		},
		//融云
		RongIMClient(newVal, oldVal) {
			oldVal = newVal;
		},
		//监听targetId 正在聊天的目标id
		targetId(newVal, oldVal) {
			oldVal = newVal;
		}
	},
	methods: {
		//获取是否开通蜘客空间
		isOpenZone() {
			this.$api.userMsg.isOpenBlog().then(res => {
				// console.log("成功========",res.result.spiderBlogFlag)
				let userInfo = JSON.parse(localStorage.getItem("loginMsg")); //存在缓存的用户信息
				// console.log("信息========",userInfo)
				userInfo.spiderBlogFlag = res.result.spiderBlogFlag;
				localStorage.setItem("loginMsg", JSON.stringify(userInfo));
			}).catch(err => {
				this.$message.error(err.msg)
			})
		},
		//初始化融云实例
		initRongyun() {
			let userInfo = JSON.parse(localStorage.getItem("loginMsg")); //存在缓存的用户信息
			this.loginMsg = userInfo; //用户信息
			this.userInfo.appKey = "tdrvipkst29q5"; //appkey
			this.userInfo.token = userInfo.tripartiteToken;
			this.RongIMClient = init(this.userInfo);
			this.$store.commit("setRongIMClient", this.RongIMClient);
			this.watchM();
		},
		//slider-bar 是主要链接融云的地方 但是会在spider-window-messagebox文件从新再连接 就是点击了某个人或者群组聊天的时候 有些值会修改的方便些
		//监听
		watchM() {
			this.RongIMClient.watch({
				// 监听会话列表变更事件, 触发时机：会话状态变化（置顶、免打扰）、会话未读数变化（未读数增加、未读数清空）、会话 @ 信息、会话最后一条消息变化
				conversation: (event) => {
					// console.log("event更新的会话列表", event.updatedConversationList);
					// 假定存在 getExistedConversationList 方法，以获取当前已存在的会话列表数据
					// const conversationList = getExistedConversationList();
					// 发生变更的会话列表
					// const updatedConversationList = event.updatedConversationList;
					// 通过 im.Conversation.merge 计算最新的会话列表
					// const latestConversationList = _this.RongIMClient.Conversation.merge({
					//   conversationList,
					//   updatedConversationList,
					// });
				},
				// 监听消息通知
				message: (event) => {
					// 新接收到的消息内容
					console.log("接收的信息112", event.message);
					// 需要对一些自定义的命令进行处理【如：同步信息、删除拉黑、保密聊天等】
					const message = event.message;
					if (message.messageType == 'RC:AsyncMsg') {
						//删除好友和清空历史
						this.deleteFriend(message.targetId);
						this.$store.dispatch("updateList", this);
					} else if (message.messageType == 'RC:CmdMsg') {
						if (message.content.name == 'SC:RemoveBlackCommand') {
							this.handleFriendTag(message.targetId);
						} else {
							this.deleteFriend(message.targetId);
						}
						this.$store.dispatch("updateList", this);
					} else {
						this.$store.dispatch("changeMsgNum", this);
						setTimeout(() => {
							this.$store.dispatch('actionChangeGroup', this)
							this.$store.dispatch('changeMsgNum', this)
							this.$store.commit('changeGroupList')
						}, 200);
					}
				},
				// 监听 IM 连接状态变化
				status: (event) => {
					// console.log("connection status:", event);
					if (event.status == 1) {
						console.log("链接中");
					}
					if (event.status == 0) {
						console.log("链接成功");
						this.$store.dispatch('actionChangeGroup', this)
						this.$store.dispatch('changeMsgNum', this)
						this.$store.commit('changeGroupList')
						// 1413421948019470337
						setTimeout(() => {
							this.getNoReadNum();
						}, 1000);
					}
				},
				// 监听聊天室 KV 数据变更
				chatroom(event) {
					/**
					 * 聊天室 KV 存储数据更新
					 * @example
					 * [
					 *  {
					 *    "key": "name",
					 *    "value": "我是小融融",
					 *    "timestamp": 1597591258338,
					 *    "chatroomId": "z002",
					 *    "type": 1 // 1: 更新（ 含:修改和新增 ）、2: 删除
					 *  },
					 * ]
					 */
					const updatedEntries = event.updatedEntries;
				},
				expansion(event) {
					/**
					 * 更新的消息拓展数据
					 * @example {
					 *    expansion: { key: 'value' },      // 设置或更新的扩展值
					 *    messageUId: 'URIT-URIT-ODMF-DURR' // 设置或更新扩展的消息 uid
					 * }
					 */
					const updatedExpansion = event.updatedExpansion;
					/**
					 * 删除的消息拓展数据
					 * @example {
					 *    deletedKeys: ['key1', 'key2'],    // 设置或更新的扩展值
					 *    messageUId: 'URIT-URIT-ODMF-DURR' // 设置或更新扩展的消息 uid
					 * }
					 */
					const deletedExpansion = event.deletedExpansion;
				},
				// 监听离线消息拉取完成
				pullFinished() {
					console.log("拉取离线消息完成");
				},
			});
		},
		//获取会话未读数 全部的会话未读
		getNoReadNum() {
			let conversationTypes = [RongIMLib.CONVERSATION_TYPE.SYSTEM];
			this.RongIMClient.Conversation.getTotalUnreadCount(
				false,
				conversationTypes
			).then((totalUnreadCount) => {
				// console.log("获取未读总数成功", totalUnreadCount);
				this.$store.dispatch("changeMsgNum", this);
			});
		},
		//获取每个模块的数值
		getNum() {
			this.$store.dispatch("changeNum", "上传表情").then((res) => {
				//  console.log("上传表情",res,this.uploadMemeNum)
			});
			this.$store.dispatch("changeNum", "上传音乐").then((res) => {
				//  console.log("上传音乐",res,this.uploadMusicNum)
			});
			this.$store.dispatch("changeNum", "发蜘客").then((res) => {
				//  console.log("发蜘客",res)
			});
			//vuex更新数量 传名称
		},
		// 跳转对应菜单界面
		goMenu(index, path) {
			// console.log(path);
			if (path == "/index/uploadMusic" && this.musicStatus != 1) {
				this.$message({
					message: "您还没有开通音乐家空间",
					type: "warning",
				});
				return;
			}
			//判断是否开启艺术家空间 阻止跳转
			if (path == "/index/uploadExpression" && this.artistStatus == 0) {
				this.$message({
					message: "您还没有开通艺术家空间",
					type: "warning",
				});
				return;
			}
			if (
				path == "/index/sendSpider" &&
				JSON.parse(localStorage.getItem("loginMsg")).spiderBlogFlag == 0
			) {
				// console.log("===", JSON.parse(localStorage.getItem("loginMsg")));
				this.$message({
					message: "您还没有开通蜘客空间",
					type: "warning",
				});
				return;
			}
			if (path != "/index/privateLetter" && path != "/index/secretaryStation" && path != "/index/addressbook") {
				localStorage.removeItem('chatRecords')
			}
			window.localStorage.setItem("index", index);
			this.current = index;
			this.$store.commit('setTargetId', '')
			this.$router.push({
				path: path
			});
		},
		//获取艺术家状态  0未开通 1 开通
		getArtistStatus() {
			api.userMsg.memberArtistStatus().then((res) => {
				this.artistStatus = res.result.isApply;
				this.avatar = res.result.avatar;
			});
			//获取音乐家状态 审核状态 0待审核 1审核成功 2审核失败
			api.userMsg.memberMusicStatus().then(res => {
				if (res.result) {
					this.musicStatus = res.result.auditStatus || 0
				} else {
					this.musicStatus = 0
				}
			});
		},
		// 退出登录
		logout() {
			api.index.logout({ uuid: localStorage.getItem('uuid') }).then((res) => {
				if (res.code == "200") {
					// 删除本地存储的token
					localStorage.removeItem("token");
					localStorage.removeItem("uuid");
					localStorage.clear()
					this.RongIMClient.disconnect().then(() => console.log('断开链接成功'));
					this.$router.push("/login");
				}
			});
		},
		deleteFriend(targetId) {
			// conversation 会话实例
			const conversation = this.RongIMClient.Conversation.get({
				targetId: targetId,
				type: RongIMLib.CONVERSATION_TYPE.PRIVATE
			});
			conversation.destory().then(() => {
				console.log('删除会话成功')
			});
		},
		handleFriendTag(targetId) {
			const type = RongIMLib.ConversationType.PRIVATE
			// 会话数组
			const conversations = [{
				type,
				targetId: targetId
			}]
			this.RongIMClient.Tag.get('0').then(tag => {
				tag.addConversations(conversations).then(code => {
					// 添加成功
					if (code === 0) {
						this.$store.dispatch("updateList", this);
					}
				})
			})
		}
	},
};
</script>

<style scoped>
.slider {
	flex-shrink: 0;
	width: 70px;
	height: 100%;
	background: #28a8cf;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 42px 0 0;
	box-sizing: border-box;
	user-select: none;
}

.header {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.avatar {
	width: 40px;
	height: 40px;
	opacity: 1;
	border: 1px solid #ffffff;
	border-radius: 50%;
	overflow: hidden;
	object-fit: none;
	margin-bottom: 20px;
	cursor: pointer;
	background: #fff;
}

.avatar img {
	height: 100%;
	width: 100%;
	/* object-fit: contain; */
}

.slider-nav {
	flex: 1;
	width: 100%;
	display: flex;
	flex-direction: column;
	overflow-y: auto;
}

.slider-nav li {
	/* width: 20px; */
	flex-direction: column;
	align-items: center;
	display: flex;
	padding: 0 5px;
	margin: 10px 0;
	position: relative;
	cursor: pointer;
}

.slider-nav li img {
	width: 24px;
	height: 24px;
	margin-bottom: 4px;
}

.slider-nav li span {
	color: #ffffff;
	font-size: 12px;
}

.slider-nav .badge {
	position: absolute;
	width: 16px;
	height: 16px;
	line-height: 16px;
	font-size: 12px;
	text-align: center;
	background: #ff6632;
	border-radius: 50%;
	top: -8px;
	right: 13px;
}

.slider-nav .badge2 {
	position: absolute;
	width: 21px;
	height: 16px;
	line-height: 16px;
	font-size: 12px;
	text-align: center;
	background: #ff6632;
	border-radius: 8px;
	top: -8px;
	right: 13px;
}

.nickName-box {
	font-size: 14px;
	color: #fff;
	margin-bottom: 10px;
}
</style>